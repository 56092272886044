.Card__Wrapper_3oLuu {
  position: relative;
  border: 1px solid rgba(18, 82, 245, 0.1);
  border: 1px solid rgba(var(--rgb--second-blue), 0.1);
  border-radius: 2px;
  box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
}

  @media print {.Card__Wrapper_3oLuu {
    margin-bottom: 20px
}
  }

.Card__HasHover_262gA {
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform, box-shadow, color;
  transition-timing-function: ease-in-out;
  will-change: transform;
}

@media screen and (hover: hover) {
    .Card__HasHover_262gA:hover {
      cursor: pointer;
      transform: translateY(-4px) translateZ(0);
    }

      .Card__HasHover_262gA:hover.Card__OnDark_1xPEe {
        background-position: 0 0;
        box-shadow: 0 24px 32px rgba(0, 7, 48, 0.15);
      }

      .Card__HasHover_262gA:hover.Card__OnLight_2oIsM {
        box-shadow: 0 24px 32px rgba(18, 82, 245, 0.15);
      }

        .Card__HasHover_262gA:hover.Card__OnLight_2oIsM .Card__Title_3lZI9 {
          color: var(--card--accent-color);
        }
  }

.Card__OnDark_1xPEe {
  --card--accent-color: var(--color--accent-light-blue);

  position: relative;
  border: 1px solid rgba(18, 82, 245, 0.5);
  background: linear-gradient(342.65deg, #1430c5 0%, #041e9b 25%, #051f9d 50%, #1139f0 100%);
  background-position: 0 50%;
  background-size: 200% 200%;
  transition: 0.3s;
}

.Card__OnLight_2oIsM {
  --card--accent-color: var(--color--second-blue);

  background: rgb(255, 255, 255);

  background: var(--color--white);
}

.Card__Heading_3Pqx- {
  margin-bottom: 12px;
  margin-bottom: var(--padding--md);
}

.Card__Title_3lZI9 {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
  transition: color 0.3s;
}

.Card__Link_2N5mT {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
