.Navigation__Wrapper_17-Tl {
}

  @media screen and (min-width: 1024px) {.Navigation__Wrapper_17-Tl {
    display: flex
}
  }

  @media (--screen--md-min) {.Navigation__Wrapper_17-Tl {
    display: flex
}
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {.Navigation__Wrapper_17-Tl {
    margin-top: 24px
}
  }

  @media (--screen--md-only) {.Navigation__Wrapper_17-Tl {
    margin-top: 24px
}
  }

  @media screen and (min-width: 1366px) {.Navigation__Wrapper_17-Tl {
    margin-top: 32px
}
  }

  @media (--screen--lg-min) {.Navigation__Wrapper_17-Tl {
    margin-top: 32px
}
  }

.Navigation__Search_3L__r {
  align-self: center;
  padding-right: 16px;
  border-right-width: 1px;
  border-right-style: solid;
  margin-right: 16px;
  cursor: pointer;
}

.Navigation__Search_3L__r svg {
    stroke: currentColor;
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Navigation__Search_3L__r svg {
      width: 16px;
      height: 16px
  }
    }

@media (--screen--md-only) {

.Navigation__Search_3L__r svg {
      width: 16px;
      height: 16px
  }
    }

@media screen and (min-width: 1366px) {

.Navigation__Search_3L__r svg {
      width: 18px;
      height: 18px
  }
    }

@media (--screen--lg-min) {

.Navigation__Search_3L__r svg {
      width: 18px;
      height: 18px
  }
    }

.Navigation__SearchOnDark_odr19 {
  border-right-color: rgba(255, 255, 255, 0.3);
  border-right-color: rgba(var(--rgb--white), 0.3);
}

.Navigation__SearchOnLight_3V955 {
  border-right-color: rgba(0, 0, 0, 0.3);
  border-right-color: rgba(var(--rgb--black), 0.3);
}

@media screen and (min-width: 1024px) {

.Navigation__Links_3ocV6 {
    display: flex;
    flex-grow: 1;
    justify-content: space-between
}
  }

@media (--screen--md-min) {

.Navigation__Links_3ocV6 {
    display: flex;
    flex-grow: 1;
    justify-content: space-between
}
  }

.Navigation__Link_2YLtS > div {
    position: relative;
    display: inline-block;
  }

.Navigation__Link_2YLtS > div::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: currentColor;
      content: "";
      opacity: 0;
      transition: opacity 0.2s;
    }

.Navigation__Link_2YLtS > div:hover {
      cursor: pointer;
    }

.Navigation__Link_2YLtS > div:hover::after {
        opacity: 0.5;
      }
