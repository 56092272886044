.Footer__Wrapper_1GN5v {
  padding-bottom: 48px;
  border-top: 1px solid #4c599e;
  margin-top: auto;
}

  @media screen and (max-width: 767px) {.Footer__Wrapper_1GN5v {
    padding-top: 48px
}
  }

  @media (--screen--xs-max) {.Footer__Wrapper_1GN5v {
    padding-top: 48px
}
  }

  @media screen and (min-width: 768px) {.Footer__Wrapper_1GN5v {
    font-size: 12px;
    line-height: 16px
}
  }

  @media (--screen--sm-min) {.Footer__Wrapper_1GN5v {
    font-size: 12px;
    line-height: 16px
}
  }

  @media screen and (min-width: 768px) and (max-width: 1365px) {.Footer__Wrapper_1GN5v {
    padding-top: 56px
}
  }

  @media (--screen--sm-min) and (--screen--md-max) {.Footer__Wrapper_1GN5v {
    padding-top: 56px
}
  }

  @media screen and (min-width: 1366px) {.Footer__Wrapper_1GN5v {
    padding-top: 80px
}
  }

  @media (--screen--lg-min) {.Footer__Wrapper_1GN5v {
    padding-top: 80px
}
  }

  @media print {.Footer__Wrapper_1GN5v {
    padding-top: 10px;
    margin-top: 20px
}
  }

@media screen and (max-width: 767px) {

.Footer__Top_3fgyB {
    display: none
}
  }

@media (--screen--xs-max) {

.Footer__Top_3fgyB {
    display: none
}
  }

@media screen and (min-width: 768px) {

.Footer__Top_3fgyB {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 -16px
}
  }

@media (--screen--sm-min) {

.Footer__Top_3fgyB {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 -16px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Footer__Top_3fgyB {
    max-height: 670px
}
  }

@media (--screen--sm-only) {

.Footer__Top_3fgyB {
    max-height: 670px
}
  }

@media screen and (min-width: 1024px) {

.Footer__Top_3fgyB {
    max-height: 290px;
    flex-direction: row;
    margin-bottom: 100px
}
  }

@media (--screen--md-min) {

.Footer__Top_3fgyB {
    max-height: 290px;
    flex-direction: row;
    margin-bottom: 100px
}
  }

@media print {

.Footer__Top_3fgyB {
    display: none
}
  }

.Footer__Inner_1JsQL {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.Footer__InHome_H49Ah:not([data-state="visible"]) {
    opacity: 0;
    transform: translateY(20px);
  }

.Footer__InHome_H49Ah[data-state="visible"] {
    transition: opacity 0.8s, transform 0.8s;
    transition-timing-function: ease-out;
  }

.Footer__Title_1SsgW {
  font-weight: 500;
}

@media screen and (max-width: 767px) {

.Footer__Title_1SsgW {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--xs-max) {

.Footer__Title_1SsgW {
    font-size: 26px;
    line-height: 32px
}
  }

@media screen and (max-width: 767px) {

.Footer__List_1fHNh {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }

@media (--screen--xs-max) {

.Footer__List_1fHNh {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Footer__List_1fHNh {
    margin-top: 24px;
    margin-top: var(--padding--xl)
}
  }

@media (--screen--sm-only) {

.Footer__List_1fHNh {
    margin-top: 24px;
    margin-top: var(--padding--xl)
}
  }

@media screen and (min-width: 1024px) {

.Footer__List_1fHNh {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }

@media (--screen--md-min) {

.Footer__List_1fHNh {
    margin-top: 16px;
    margin-top: var(--padding--lg)
}
  }

@media screen and (min-width: 768px) {

.Footer__Link_3Ac6V {
    font-size: 12px;
    line-height: 16px
}
  }

@media (--screen--sm-min) {

.Footer__Link_3Ac6V {
    font-size: 12px;
    line-height: 16px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .Footer__Link_3Ac6V:not(:last-child) {
      margin-bottom: 16px;
    }
  }

@media (--screen--sm-only) {
    .Footer__Link_3Ac6V:not(:last-child) {
      margin-bottom: 16px;
    }
  }

@media screen and (min-width: 1024px) {
    .Footer__Link_3Ac6V:not(:last-child) {
      margin-bottom: 12px;
    }
  }

@media (--screen--md-min) {
    .Footer__Link_3Ac6V:not(:last-child) {
      margin-bottom: 12px;
    }
  }

.Footer__Link_3Ac6V > div {
    position: relative;
    display: inline-block;
  }

.Footer__Link_3Ac6V > div::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: currentColor;
      content: "";
      opacity: 0;
      transition: opacity 0.2s;
    }

.Footer__Link_3Ac6V > div:hover {
      cursor: pointer;
    }

.Footer__Link_3Ac6V > div:hover::after {
        opacity: 0.5;
      }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Footer__Block_vn6e8:nth-child(3) {
      margin-bottom: 140px
  }
    }

@media (--screen--sm-only) {

.Footer__Block_vn6e8:nth-child(3) {
      margin-bottom: 140px
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Footer__Block_vn6e8 {
    width: 33.33%;
    padding: 0 16px;
    margin-bottom: 32px
}
  }

@media (--screen--sm-only) {

.Footer__Block_vn6e8 {
    width: 33.33%;
    padding: 0 16px;
    margin-bottom: 32px
}
  }

@media screen and (min-width: 1024px) {

.Footer__Block_vn6e8 {
    width: 25%;
    padding: 0 16px;
    margin-bottom: 24px
}
  }

@media (--screen--md-min) {

.Footer__Block_vn6e8 {
    width: 25%;
    padding: 0 16px;
    margin-bottom: 24px
}
  }
