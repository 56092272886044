.SuccessPopup__Wrapper_25C4D {
  padding-bottom: 48px;
}

  .SuccessPopup__Wrapper_25C4D a:not([class]) {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    font-weight: 500;
  }

  .SuccessPopup__Wrapper_25C4D a:not([class])::after {
      content: none;
    }

@media screen and (min-width: 1px) and (max-width: 1023px) {

.SuccessPopup__Title_2kMSZ {
    margin-bottom: 8px
}
  }

@media (--screen--xs-min) and (--screen--sm-max) {

.SuccessPopup__Title_2kMSZ {
    margin-bottom: 8px
}
  }

@media screen and (min-width: 1024px) {

.SuccessPopup__Title_2kMSZ {
    margin-bottom: 16px
}
  }

@media (--screen--md-min) {

.SuccessPopup__Title_2kMSZ {
    margin-bottom: 16px
}
  }
