.Link__Default_2-fhp {
  display: inline-block;
}

  .Link__Default_2-fhp:hover > span::after,
      .Link__Default_2-fhp:hover > span::before {
        cursor: pointer;
        opacity: 1;
      }

  .Link__Default_2-fhp > span {
    position: relative;
    font-weight: 500;
  }

  .Link__Default_2-fhp > span::after,
    .Link__Default_2-fhp > span::before {
      position: absolute;
      content: "";
      opacity: 0.3;
      transform: translateX(0);
      transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
    }

  .Link__Default_2-fhp > span:hover {
      cursor: pointer;
    }

.Link__Arrow_SwjbE,
.Link__Mail_22S8B,
.Link__Dropdown_qFz_b {
}

.Link__Arrow_SwjbE:hover > span::after, .Link__Mail_22S8B:hover > span::after {
        transform: translateX(6px);
      }

.Link__Arrow_SwjbE > span::after, .Link__Mail_22S8B > span::after {
      right: 0;
      bottom: 3px;
    }

.Link__Arrow_SwjbE > span::before, .Link__Mail_22S8B > span::before {
      /* content: none; */
    }

.Link__Arrow_SwjbE > span {
    padding-right: 20px;
  }

.Link__Arrow_SwjbE > span::after {
      width: 16px;
      height: 9px;
    }

.Link__Mail_22S8B > span {
    padding-right: 19px;
  }

.Link__Mail_22S8B > span::after {
      width: 15px;
      height: 11px;
    }

.Link__Dropdown_qFz_b {
  --rotate: -45deg;
}

[data-state="open"] .Link__Dropdown_qFz_b {
    --rotate: 45deg;
  }

.Link__Dropdown_qFz_b > span {
    padding-right: 14px;
  }

.Link__Dropdown_qFz_b > span::before,
    .Link__Dropdown_qFz_b > span::after {
      top: 50%;
      right: 0;
      width: 7px;
      height: 1px;
      background: rgb(18, 82, 245);
      background: var(--color--second-blue);
      will-change: transform;
    }

.Link__Dropdown_qFz_b > span::before {
      transform: translate(2px, -50%) rotate(var(--rotate));
    }

.Link__Dropdown_qFz_b > span::after {
      right: 0;
      transform: translate(-2px, -50%) rotate(calc(var(--rotate) * -1));
    }

.Link__Light_KXrzU > span {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
  }

.Link__Light_KXrzU.Link__Arrow_SwjbE > span::after {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjMTI1MkY1IiBkPSJNLjQgNC4wMzVoMTF2MUguNHoiLz48cGF0aCBkPSJNMTYuMDA2IDQuNTdsLTQuOTY5LTMuMTcydjYuMzQ2bDQuOTY5LTMuMTczeiIgZmlsbD0iIzEyNTJGNSIvPjwvc3ZnPgo=);
      }

.Link__Light_KXrzU.Link__Mail_22S8B > span::after {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjUgNi42NEwxIDEuNDRWMTBoMTNWMS40NGwtNi41IDUuMnpNMTUgMTFIMFYwaDE1djExek0yLjA1IDFMNy41IDUuMzYgMTIuOTUgMUgyLjA1eiIgZmlsbD0iIzEyNTJGNSIvPjwvc3ZnPgo=);
      }

.Link__Dark_3WPu3.Link__Arrow_SwjbE > span::after {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNLjQgNC4wMzVoMTF2MUguNHoiLz48cGF0aCBkPSJNMTYuMDA2IDQuNTdsLTQuOTY4LTMuMTcydjYuMzQ2bDQuOTY4LTMuMTczeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPgo=);
      }

.Link__Dark_3WPu3.Link__Mail_22S8B > span::after {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjUgNi42NEwxIDEuNDRWMTBoMTNWMS40NGwtNi41IDUuMnpNMTUgMTFIMFYwaDE1djExek0yLjA1IDFMNy41IDUuMzYgMTIuOTUgMUgyLjA1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPgo=);
      }

.Link__Dark_3WPu3.Link__Dropdown_qFz_b > span::after,
      .Link__Dark_3WPu3.Link__Dropdown_qFz_b > span::before {
        background: rgb(255, 255, 255);
        background: var(--color--white);
      }
