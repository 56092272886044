.TabWrapper__Wrapper_3cvw5 {
  padding: 24px 24px 32px;
  margin-bottom: -116px;
}

.TabWrapper__WrapperChilderForm_3dWGJ {
  padding: 24px;
  margin-bottom: -116px;
}

.TabWrapper__Title_1sRut {
  margin-bottom: 8px;
  margin-bottom: var(--padding--sm);
}
