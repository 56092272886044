@-webkit-keyframes AttachFile__rotate_oFHsh {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes AttachFile__rotate_oFHsh {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(180deg);
  }
}

.AttachFile__Wrapper_1i_EM {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (min-width: 768px) {

.AttachFile__AddButtonWrapper_hO-8n {
    display: flex;
    flex-wrap: wrap
}
  }

@media (--screen--sm-min) {

.AttachFile__AddButtonWrapper_hO-8n {
    display: flex;
    flex-wrap: wrap
}
  }

@media screen and (min-width: 768px) {

.AttachFile__AddButton_2f-fn,
.AttachFile__Notice_3hE9W {
    flex-shrink: 0
}
  }

@media (--screen--sm-min) {

.AttachFile__AddButton_2f-fn,
.AttachFile__Notice_3hE9W {
    flex-shrink: 0
}
  }

.AttachFile__AddButton_2f-fn {
  position: relative;
  padding-left: 19px;
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-weight: 500;
}

.AttachFile__AddButton_2f-fn::before {
    position: absolute;
    top: 4px;
    left: -3px;
    width: 13px;
    height: 13px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuNSAwdjEzTTEzIDYuNUgwIiBzdHJva2U9IiMxMjUyRjUiLz48L3N2Zz4K);
    content: "";
    will-change: transform;
  }

.AttachFile__AddButton_2f-fn:hover {
    cursor: pointer;
  }

.AttachFile__AddButton_2f-fn:hover::before {
      -webkit-animation: AttachFile__rotate_oFHsh 0.6s cubic-bezier(0.77, 0, 0.175, 1) 1;
              animation: AttachFile__rotate_oFHsh 0.6s cubic-bezier(0.77, 0, 0.175, 1) 1;
    }

@media screen and (max-width: 767px) {

.AttachFile__AddButton_2f-fn {
    margin-bottom: 12px;
    margin-bottom: var(--padding--md)
}
  }

@media (--screen--xs-max) {

.AttachFile__AddButton_2f-fn {
    margin-bottom: 12px;
    margin-bottom: var(--padding--md)
}
  }

.AttachFile__AddButton_2f-fn.AttachFile___error_1MapQ {
    color: rgb(249, 57, 57);
    color: var(--color--red);
  }

.AttachFile__Notice_3hE9W {
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--rgb--black), 0.5);
}

@media screen and (min-width: 768px) {

.AttachFile__Notice_3hE9W {
    margin-left: 16px
}
  }

@media (--screen--sm-min) {

.AttachFile__Notice_3hE9W {
    margin-left: 16px
}
  }

.AttachFile__Files_2aYOg {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: calc(12px * -1);
  margin-bottom: calc(var(--padding--md) * -1);
}

.AttachFile__Item_1dSfg {
  margin-bottom: 12px;
  margin-bottom: var(--padding--md);
  border-radius: 2px;
}

.AttachFile__Item_1dSfg:not(:last-child) {
    margin-right: 12px;
    margin-right: var(--padding--md);
  }

.AttachFile__File_i6dTc {
  position: relative;
  padding: 12px 48px 14px 16px;
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

.AttachFile__File_i6dTc > span {
    position: relative;
    display: inline-block;
    padding-left: 21px;
  }

.AttachFile__File_i6dTc > span::before {
      position: absolute;
      top: 3px;
      left: 1px;
      width: 13px;
      height: 16px;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjUgMTUuNUguNVYuNWg1LjlsNi4xIDUuNjgzVjE1LjV6IiBzdHJva2U9IiMxMjUyRjUiLz48L3N2Zz4K);
      content: "";
    }

.AttachFile__onLightBlue_3RpG_ {
  border: 1px solid rgba(18, 82, 245, 0.2);
  border: 1px solid rgba(var(--rgb--second-blue), 0.2);
  border-radius: 2px;
}

.AttachFile__Errors_32r3f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
  margin-top: var(--padding--md);
  margin-bottom: calc(12px * -1);
  margin-bottom: calc(var(--padding--md) * -1);
}

.AttachFile__Error_26uoW {
  padding: 12px 16px 16px;
  background: rgba(249, 57, 57, 0.1);
  background: rgba(var(--rgb--red), 0.1);
}

.AttachFile__DeleteFile_cfBgR {
  position: absolute;
  top: 17px;
  right: 14px;
  width: 14px;
  height: 14px;
}

.AttachFile__DeleteFile_cfBgR::after {
    position: absolute;
    width: 14px;
    height: 14px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMiAxMk0xMyAxTDEgMTMiIHN0cm9rZT0iI0Y5MzkzOSIvPjwvc3ZnPgo=);
    content: "";
  }

.AttachFile__DeleteFile_cfBgR:hover {
    cursor: pointer;
  }
