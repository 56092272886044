.Button__Default_1l-sy {
  display: inline-block;
  color: var(--color);
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-timing-function: ease-in-out;
  white-space: nowrap;
}

  @media screen and (max-width: 767px) {.Button__Default_1l-sy {
    width: 100%
}
  }

  @media (--screen--xs-max) {.Button__Default_1l-sy {
    width: 100%
}
  }

.Button__Xs_1_Mw1,
.Button__Xl_LKDbP {
  --color: var(--color--white);
  min-width: 140px;
  background: rgb(18, 82, 245);
  background: var(--color--second-blue);
  border-radius: 2px;

  /* Четвёртое время в `transition-duration` из-за autoprefixer,
  он создаёт дополнительное свойство `-webkit-transform` в `transition-property`. */
  transition-duration: 0.2s, 0.125s, 0.125s, 0.125s;
  transition-property: background, box-shadow, transform;
}

.Button__Xs_1_Mw1:hover,
  .Button__Xs_1_Mw1:active,
  .Button__Xl_LKDbP:hover,
  .Button__Xl_LKDbP:active {
    background: rgb(14, 68, 204);
  }

.Button__Xs_1_Mw1:hover,
  .Button__Xs_1_Mw1[data-state="active"],
  .Button__Xl_LKDbP:hover,
  .Button__Xl_LKDbP[data-state="active"] {
    transform: translateY(-2px);
  }

.Button__Xs_1_Mw1:active, .Button__Xl_LKDbP:active {
    transform: translateY(0);
  }

.Button__Xs_1_Mw1:disabled, .Button__Xl_LKDbP:disabled {
    background: rgba(18, 82, 245, 0.25);
    background: rgba(var(--rgb--second-blue), 0.25);
    pointer-events: none;
  }

.Button__Xl_LKDbP,
.Button__Outline_Mi2Ja {
  padding: 0 32px;
  font-size: 16px;
  line-height: 56px;
}

.Button__Xs_1_Mw1 {
  padding: 0 24px;
  font-size: 12px;
  line-height: 36px;
}

.Button__Rounded_28TRc {
  min-width: 100px;
  padding: 0 12px;
  border-width: 1px;
  border-style: solid;
  background: transparent;
  border-radius: 40px;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 28px;
  transition-duration: 0.3s;
  transition-property: background, border-color, color;
}

.Button__Rounded_28TRc:hover,
  .Button__Rounded_28TRc[data-state="active"] {
    background: rgb(18, 82, 245);
    background: var(--color--second-blue);
  }

.Button__Rounded_28TRc:active {
    background: rgb(14, 68, 204);
  }

.Button__Rounded_28TRc.Button__OnLight_sPOpd:hover,
    .Button__Rounded_28TRc.Button__OnLight_sPOpd[data-state="active"],
    .Button__Rounded_28TRc.Button__OnDark_4XGmR:hover,
    .Button__Rounded_28TRc.Button__OnDark_4XGmR[data-state="active"] {
      border-color: rgb(18, 82, 245);
      border-color: var(--color--second-blue);
    }

.Button__Rounded_28TRc.Button__OnLight_sPOpd:active, .Button__Rounded_28TRc.Button__OnDark_4XGmR:active {
      border-color: rgb(14, 68, 204);
    }

.Button__Rounded_28TRc.Button__OnLight_sPOpd {
    --color: var(--color--second-blue);

    border-color: rgba(18, 82, 245, 0.2);

    border-color: rgba(var(--rgb--second-blue), 0.2);
  }

.Button__Rounded_28TRc.Button__OnLight_sPOpd:hover,
    .Button__Rounded_28TRc.Button__OnLight_sPOpd:active,
    .Button__Rounded_28TRc.Button__OnLight_sPOpd[data-state="active"] {
      --color: var(--color--white);
    }

.Button__Rounded_28TRc.Button__OnDark_4XGmR {
    --color: var(--color--white);

    border-color: rgba(255, 255, 255, 0.3);

    border-color: rgba(var(--rgb--white), 0.3);
  }

.Button__Outline_Mi2Ja {
  --color: var(--color--second-blue);
  min-width: 140px;
  border: 1px solid rgba(18, 82, 245, 0.2);
  border: 1px solid rgba(var(--rgb--second-blue), 0.2);
  background: transparent;
  border-radius: 2px;
}

.Button__Shadow_OUwTn.Button__OnLight_sPOpd:hover,
    .Button__Shadow_OUwTn.Button__OnLight_sPOpd[data-state="active"] {
      box-shadow: 0 8px 12px rgba(18, 82, 245, 0.3);
    }

.Button__Shadow_OUwTn.Button__OnLight_sPOpd:active {
      box-shadow: 0 2px 6px rgba(18, 82, 245, 0.5);
    }

.Button__Shadow_OUwTn.Button__OnDark_4XGmR:hover,
    .Button__Shadow_OUwTn.Button__OnDark_4XGmR[data-state="active"] {
      box-shadow: 0 8px 12px rgba(0, 7, 48, 0.3);
    }

.Button__Shadow_OUwTn.Button__OnDark_4XGmR:active {
      box-shadow: 0 2px 8px rgba(0, 7, 48, 0.5);
    }

.Button__OnDark_4XGmR {
  /* TODO: */
}

.Button__OnLight_sPOpd {
  /* TODO: */
}

.Button__IsLoading_2Jwol {
  position: relative;
  color: transparent;
  pointer-events: none;
}

.Button__IsLoading_2Jwol::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent transparent transparent var(--color);
    -webkit-animation: Button__spin_272Uh 1.1s infinite linear;
            animation: Button__spin_272Uh 1.1s infinite linear;
    border-radius: 50%;
    content: "";
    transform: translate(-50%, -50%);
  }

@-webkit-keyframes Button__spin_272Uh {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes Button__spin_272Uh {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.Button__IsError_3QP2A {
  position: relative;
  color: transparent;
  pointer-events: none;
}

.Button__IsError_3QP2A::before,
  .Button__IsError_3QP2A::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 2px;
    background-color: var(--color);
    content: "";
    transform: translate(-50%, -50%) rotate(var(--rotate));
  }

.Button__IsError_3QP2A::before {
    --rotate: 45deg;
  }

.Button__IsError_3QP2A::after {
    --rotate: -45deg;
  }
