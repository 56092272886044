.Cookie__Wrapper_62-X9 {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 50%;
  width: 100%;
  min-height: 70px;
  border-top: 1px solid #dbe5ff;
  background: rgb(255, 255, 255);
  background: var(--color--white);
  box-shadow: 0 -8px 24px rgba(18, 82, 245, 0.1);
  box-shadow: 0 -8px 24px rgba(var(--rgb--second-blue), 0.1);
  transform: translateX(-50%);
  transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.8s;
}

  @media screen and (max-width: 767px) {.Cookie__Wrapper_62-X9 {
    padding: 20px 0 24px
}
  }

  @media (--screen--xs-max) {.Cookie__Wrapper_62-X9 {
    padding: 20px 0 24px
}
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {.Cookie__Wrapper_62-X9 {
    padding: 24px 0
}
  }

  @media (--screen--sm-only) {.Cookie__Wrapper_62-X9 {
    padding: 24px 0
}
  }

  @media screen and (min-width: 1024px) {.Cookie__Wrapper_62-X9 {
    padding: 16px 0
}
  }

  @media (--screen--md-min) {.Cookie__Wrapper_62-X9 {
    padding: 16px 0
}
  }

@media screen and (min-width: 768px) {

.Cookie__Inner_2T9zc {
    display: flex;
    align-items: center;
    justify-content: space-between
}
  }

@media (--screen--sm-min) {

.Cookie__Inner_2T9zc {
    display: flex;
    align-items: center;
    justify-content: space-between
}
  }

@media screen and (max-width: 767px) {

.Cookie__Text_2c07Y {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }

@media (--screen--xs-max) {

.Cookie__Text_2c07Y {
    margin-bottom: 16px;
    margin-bottom: var(--padding--lg)
}
  }

@media screen and (min-width: 768px) {

.Cookie__Text_2c07Y {
    margin-right: 24px;
    margin-right: var(--padding--xl)
}
  }

@media (--screen--sm-min) {

.Cookie__Text_2c07Y {
    margin-right: 24px;
    margin-right: var(--padding--xl)
}
  }

.Cookie__WrapperEnter_1dkTl,
.Cookie__WrapperExitActive_pC_4T {
  transform: translateX(-50%) translateY(100%);
}
