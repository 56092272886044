.Menu__Wrapper_nkG6U {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: calc(100vh + 10px);
  padding-right: var(--scrollBarWidth);
  background-color: rgb(0, 18, 117);
  background-color: var(--color--main-blue);
  color: rgb(255, 255, 255);
  color: var(--color--white);
}

  @media screen and (max-width: 767px) {.Menu__Wrapper_nkG6U {
    padding-top: 104px
}
  }

  @media (--screen--xs-max) {.Menu__Wrapper_nkG6U {
    padding-top: 104px
}
  }

  @media screen and (min-width: 768px) {.Menu__Wrapper_nkG6U {
    padding-top: 88px
}
  }

  @media (--screen--sm-min) {.Menu__Wrapper_nkG6U {
    padding-top: 88px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Menu__Top_31GGn {
    height: 706px
}
  }

@media (--screen--sm-only) {

.Menu__Top_31GGn {
    height: 706px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Menu__Top_31GGn {
    height: 487px
}
  }

@media (--screen--md-only) {

.Menu__Top_31GGn {
    height: 487px
}
  }

@media screen and (min-width: 1366px) {

.Menu__Top_31GGn {
    height: 549px
}
  }

@media (--screen--lg-min) {

.Menu__Top_31GGn {
    height: 549px
}
  }

@media screen and (min-width: 768px) {

.Menu__TopInner_11ULJ {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 -16px
}
  }

@media (--screen--sm-min) {

.Menu__TopInner_11ULJ {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 -16px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Menu__TopInner_11ULJ {
    max-height: 730px
}
  }

@media (--screen--sm-only) {

.Menu__TopInner_11ULJ {
    max-height: 730px
}
  }

@media screen and (min-width: 1024px) {

.Menu__TopInner_11ULJ {
    max-height: 360px
}
  }

@media (--screen--md-min) {

.Menu__TopInner_11ULJ {
    max-height: 360px
}
  }

.Menu__Inner_KnOyX {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.Menu__Title_1CBHH {
  font-weight: 500;
}

@media screen and (max-width: 767px) {

.Menu__Title_1CBHH {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--xs-max) {

.Menu__Title_1CBHH {
    font-size: 26px;
    line-height: 32px
}
  }

.Menu__List_3OfPB {
  margin-top: 24px;
  margin-top: var(--padding--xl);
}

@media screen and (max-width: 767px) {

.Menu__Link_2LBiH:not(:last-child) {
      margin-bottom: 24px
  }
    }

@media (--screen--xs-max) {

.Menu__Link_2LBiH:not(:last-child) {
      margin-bottom: 24px
  }
    }

@media screen and (min-width: 768px) {

.Menu__Link_2LBiH:not(:last-child) {
      margin-bottom: 16px
  }
    }

@media (--screen--sm-min) {

.Menu__Link_2LBiH:not(:last-child) {
      margin-bottom: 16px
  }
    }

.Menu__Link_2LBiH > div {
    position: relative;
    display: inline-block;
  }

.Menu__Link_2LBiH > div::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: currentColor;
      content: "";
      opacity: 0;
      transition: opacity 0.2s;
    }

.Menu__Link_2LBiH > div:hover {
      cursor: pointer;
    }

.Menu__Link_2LBiH > div:hover::after {
        opacity: 0.5;
      }

@media screen and (max-width: 767px) {

.Menu__Block_1Ok_q {
    margin-bottom: 40px
}
  }

@media (--screen--xs-max) {

.Menu__Block_1Ok_q {
    margin-bottom: 40px
}
  }

@media screen and (min-width: 768px) {

.Menu__Block_1Ok_q {
    margin-bottom: 32px
}
  }

@media (--screen--sm-min) {

.Menu__Block_1Ok_q {
    margin-bottom: 32px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Menu__Block_1Ok_q {
    width: 33.33%;
    padding: 0 16px
}
  }

@media (--screen--sm-only) {

.Menu__Block_1Ok_q {
    width: 33.33%;
    padding: 0 16px
}
  }

@media screen and (min-width: 1024px) {

.Menu__Block_1Ok_q {
    width: 25%;
    padding: 0 16px
}
  }

@media (--screen--md-min) {

.Menu__Block_1Ok_q {
    width: 25%;
    padding: 0 16px
}
  }

.Menu__Enter_3CJ8Z {
  opacity: 0;
  transform: scale(1.01);
  transform-origin: center;
  transition: opacity 0.4s, transform 0.4s;
}

.Menu__EnterActive_2M5-A {
  opacity: 1;
  transform: translateY(0);
}

.Menu__Exit_h0ZsQ {
  opacity: 1;
  transform: translateY(0);
  transform-origin: center;
  transition: opacity 0.4s, transform 0.4s;
}

.Menu__ExitActive_xYFcD {
  opacity: 0;
  transform: scale(1.01);
}
