.Header__Inner_kiHlk {
}

  @media screen and (max-width: 767px) {

  [data-state="open"] .Header__Inner_kiHlk {
      width: 126px;
      margin-right: 0
  }
    }

  @media (--screen--xs-max) {

  [data-state="open"] .Header__Inner_kiHlk {
      width: 126px;
      margin-right: 0
  }
    }

.Header__InHome_fW6vy:not([data-state="visible"]) {
    opacity: 0;
    transform: translateY(-30px);
  }

.Header__InHome_fW6vy[data-state="visible"] {
    transition-property: opacity, transform;
    transition-timing-function: ease-out;
  }

@media screen and (max-width: 1023px) {

.Header__InHome_fW6vy[data-state="visible"] {
      transition-duration: 0.6s, 0.6s
  }
    }

@media (--screen--sm-max) {

.Header__InHome_fW6vy[data-state="visible"] {
      transition-duration: 0.6s, 0.6s
  }
    }

@media screen and (min-width: 1024px) {

.Header__InHome_fW6vy[data-state="visible"] {
      transition-delay: 1s;
      transition-duration: 0.8s, 1.5s
  }
    }

@media (--screen--md-min) {

.Header__InHome_fW6vy[data-state="visible"] {
      transition-delay: 1s;
      transition-duration: 0.8s, 1.5s
  }
    }

.Header__Wrapper_1yov7 {
  z-index: 100;
  left: 0;
  width: 100%;
  padding-bottom: 16px;
  color: var(--menuColor);
  transition: color 0.3s;
}

.Header__Wrapper_1yov7::before,
  .Header__Wrapper_1yov7::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }

.Header__Wrapper_1yov7::after {
    box-shadow: 0 12px 20px rgba(0, 18, 117, 0.3);
  }

.Header__Wrapper_1yov7::before {
    background: linear-gradient(180deg, #001275 0%, #001aaa 100%);
  }

.Header__Wrapper_1yov7[data-direction-state="top"] {
    position: absolute;
    top: 0;
  }

.Header__Wrapper_1yov7[data-direction-state="up"],
  .Header__Wrapper_1yov7[data-direction-state="down"] {
    position: fixed;
    bottom: 100%;
    transition: color 0.3s, transform 0.3s;
  }

.Header__Wrapper_1yov7[data-direction-state="up"]::before, .Header__Wrapper_1yov7[data-direction-state="down"]::before {
      opacity: 1;
    }

.Header__Wrapper_1yov7[data-direction-state="up"] .Header__Navigation_3CREZ, .Header__Wrapper_1yov7[data-direction-state="down"] .Header__Navigation_3CREZ {
      transform: translateY(-8px);
    }

.Header__Wrapper_1yov7[data-direction-state="up"] .Header__LogoItems_1rTCu, .Header__Wrapper_1yov7[data-direction-state="down"] .Header__LogoItems_1rTCu {
      transform: scale(0.75);
    }

.Header__Wrapper_1yov7[data-direction-state="up"] {
    border-bottom: 1px solid rgba(0, 196, 255, 0.5);
    border-bottom: 1px solid rgba(var(--rgb--accent-light-blue), 0.5);
    transform: translateY(calc(-10px + 100%));
  }

.Header__Wrapper_1yov7[data-direction-state="up"]::after {
      opacity: 1;
    }

.Header__Wrapper_1yov7[data-state="open"] {
    position: fixed;
    right: 0;
    left: 0;
    padding-right: var(--scrollBarWidth);
    transform: none;
    transition: none;
  }

.Header__Wrapper_1yov7[data-state="open"][data-direction-state="up"] {
      transform: translateY(calc(-10px + 100%));
    }

.Header__Wrapper_1yov7[data-state="open"] .Header__Navigation_3CREZ {
      opacity: 0;
    }

.Header__Wrapper_1yov7[data-state="open"] .Header__Burger_3Kk23 span {
        --distance: 0;

        background-color: transparent;
      }

.Header__Wrapper_1yov7[data-state="open"] .Header__Burger_3Kk23 span::before,
        .Header__Wrapper_1yov7[data-state="open"] .Header__Burger_3Kk23 span::after {
          transition-delay: 0s, 0.2s, 0.2s;
        }

.Header__Wrapper_1yov7[data-state="open"] .Header__Burger_3Kk23 span::before {
          transform: rotate(45deg);
        }

.Header__Wrapper_1yov7[data-state="open"] .Header__Burger_3Kk23 span::after {
          transform: rotate(-45deg);
        }

.Header__Wrapper_1yov7[data-direction-state="down"] {
    /* transform: translateY(calc(-100%)); */
  }

.Header__Wrapper_1yov7[data-theme="onLight"] {
    --menuColor: var(--color--black);
  }

.Header__Wrapper_1yov7[data-theme="onDark"] {
    --menuColor: var(--color--white);
  }

@media screen and (max-width: 1023px) {

.Header__Wrapper_1yov7 {
    padding-top: 24px
}
  }

@media (--screen--sm-max) {

.Header__Wrapper_1yov7 {
    padding-top: 24px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Header__Wrapper_1yov7 {
    padding-top: 32px;
    font-size: 12px;
    line-height: 16px
}
  }

@media (--screen--md-only) {

.Header__Wrapper_1yov7 {
    padding-top: 32px;
    font-size: 12px;
    line-height: 16px
}
  }

@media screen and (min-width: 1366px) {

.Header__Wrapper_1yov7 {
    padding-top: 25px
}
  }

@media (--screen--lg-min) {

.Header__Wrapper_1yov7 {
    padding-top: 25px
}
  }

@media print {

.Header__Wrapper_1yov7 {
    display: none
}
  }

.Header__Burger_3Kk23 {
  display: flex;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {

.Header__Burger_3Kk23 {
    width: 23px
}
  }

@media (--screen--xs-max) {

.Header__Burger_3Kk23 {
    width: 23px
}
  }

@media screen and (min-width: 768px) {

.Header__Burger_3Kk23 {
    width: 14px
}
  }

@media (--screen--sm-min) {

.Header__Burger_3Kk23 {
    width: 14px
}
  }

.Header__Burger_3Kk23 span {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: background-color 0.3s;
  }

.Header__Burger_3Kk23 span::before,
    .Header__Burger_3Kk23 span::after {
      position: absolute;
      width: 100%;
      height: 1px;
      background: currentColor;
      content: "";
      transform-origin: center;
      transition-delay: 0.2s, 0s, 0s;
      transition-duration: 0.3s;
    }

.Header__Burger_3Kk23 span::before {
      top: var(--distance);
      transition-property: top, transform;
      will-change: top, transform;
    }

.Header__Burger_3Kk23 span::after {
      bottom: var(--distance);
      transition-property: bottom, transform;
      will-change: bottom, transform;
    }

@media screen and (max-width: 767px) {

.Header__Burger_3Kk23 span {
      --distance: 9px
  }
    }

@media (--screen--xs-max) {

.Header__Burger_3Kk23 span {
      --distance: 9px
  }
    }

@media screen and (min-width: 768px) {

.Header__Burger_3Kk23 span {
      --distance: 5px
  }
    }

@media (--screen--sm-min) {

.Header__Burger_3Kk23 span {
      --distance: 5px
  }
    }

.Header__LogoItems_1rTCu {
  display: flex;
  align-items: center;
  margin-right: auto;
  transform-origin: left;
  transition: transform 0.3s, opacity 0.3s;
}

@media screen and (max-width: 767px) {

[data-state="open"] .Header__LogoItems_1rTCu {
      display: none
  }
    }

@media (--screen--xs-max) {

[data-state="open"] .Header__LogoItems_1rTCu {
      display: none
  }
    }

.Header__Logo_OBkKN {
  flex-shrink: 0;
}

.Header__Top_1lifV {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  /* &[data-theme="light"] {
    color: var(--color--black);
  }

  &[data-theme="dark"] {
    color: var(--color--white);
  } */
}

.Header__TopItems_1uAe9 {
  position: relative;
  display: flex;
  flex-shrink: 0;
}

@media screen and (min-width: 768px) {

[data-state="open"] .Header__TopItems_1uAe9 {
      opacity: 0;
      visibility: hidden
  }
    }

@media (--screen--sm-min) {

[data-state="open"] .Header__TopItems_1uAe9 {
      opacity: 0;
      visibility: hidden
  }
    }

@media screen and (max-width: 767px) {

.Header__TopItems_1uAe9 {
    width: 31px;
    height: 31px
}
  }

@media (--screen--xs-max) {

.Header__TopItems_1uAe9 {
    width: 31px;
    height: 31px
}
  }

@media screen and (min-width: 768px) {

.Header__TopItems_1uAe9 {
    transition: opacity 0.3s, visibility 0.3s
}
  }

@media (--screen--sm-min) {

.Header__TopItems_1uAe9 {
    transition: opacity 0.3s, visibility 0.3s
}
  }

.Header__Phone_1ITm2 {
}

@media screen and (min-width: 768px) {

.Header__Phone_1ITm2 {
    margin-right: 32px
}
  }

@media (--screen--sm-min) {

.Header__Phone_1ITm2 {
    margin-right: 32px
}
  }

@media screen and (max-width: 767px) {

.Header__Lang_QCON1,
.Header__PhoneWithIcon_1wyZQ {
    position: absolute;
    transition: opacity 0.3s, visibility 0.3s
}
  }

@media (--screen--xs-max) {

.Header__Lang_QCON1,
.Header__PhoneWithIcon_1wyZQ {
    position: absolute;
    transition: opacity 0.3s, visibility 0.3s
}
  }

@media screen and (max-width: 767px) {

[data-state="open"] .Header__Lang_QCON1 {
      opacity: 1;
      visibility: visible
  }
    }

@media (--screen--xs-max) {

[data-state="open"] .Header__Lang_QCON1 {
      opacity: 1;
      visibility: visible
  }
    }

@media screen and (max-width: 767px) {

.Header__Lang_QCON1 {
    font-size: 26px;
    font-weight: 300;
    line-height: 32px;
    opacity: 0;
    visibility: hidden
}
  }

@media (--screen--xs-max) {

.Header__Lang_QCON1 {
    font-size: 26px;
    font-weight: 300;
    line-height: 32px;
    opacity: 0;
    visibility: hidden
}
  }

.Header__PhoneWithIcon_1wyZQ {
}

@media screen and (max-width: 767px) {

[data-state="open"] .Header__PhoneWithIcon_1wyZQ {
      opacity: 0;
      visibility: hidden
  }
    }

@media (--screen--xs-max) {

[data-state="open"] .Header__PhoneWithIcon_1wyZQ {
      opacity: 0;
      visibility: hidden
  }
    }

.Header__PhoneWithIcon_1wyZQ path {
    fill: currentColor;
  }

.Header__Navigation_3CREZ {
  transition: transform 0.3s;
}

.Header__Logo_OBkKN img {
  min-height: 31px;
}