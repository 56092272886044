.Tabs__Count_2B63v {
  margin-left: 5px;
  opacity: 0.3;
}

.Tabs__Tab_2MJnd {
  display: flex;
  margin-bottom: 16px;
}

.Tabs__Tab_2MJnd:not(:last-child) {
    margin-right: 16px;
  }

.Tabs__TabActive_ROKw0 {
  border-color: rgb(18, 82, 245);
  border-color: var(--color--second-blue);
  background-color: rgb(18, 82, 245);
  background-color: var(--color--second-blue);
  color: rgb(255, 255, 255) !important;
  color: var(--color--white) !important;
}

.Tabs__TabSimple_16ONw {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-weight: 500;
  transition: color ease-in-out 0.3s;
}

.Tabs__TabSimple_16ONw:hover {
    cursor: pointer;
  }

.Tabs__TabSimpleActive_2hs2U {
  color: rgb(0, 0, 0);
  color: var(--color--black);
  pointer-events: none;
}

.Tabs__TabThin_2Gnf8 {
  color: rgba(18, 82, 245, 0.3);
  color: rgba(var(--rgb--second-blue), 0.3);
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  transition: color ease-in-out 0.3s;
}

.Tabs__TabThin_2Gnf8:hover {
    cursor: pointer;
  }

.Tabs__TabThin_2Gnf8:not(:last-child) {
    margin-right: 40px;
    margin-right: var(--padding--3xl);
  }

.Tabs__TabThinActive_19K22 {
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  pointer-events: none;
}

.Tabs__Tabs_BDtX_ {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -16px;
}

@media print {

.Tabs__Tabs_BDtX_ {
    margin-top: 20px
}
  }
