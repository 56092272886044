.TextArea__Wrapper_1fkNu {
  --time: 0.3s;
  --transform--label: translate(19px, -24px) scale(0.72);
  --padding: 24px;

  position: relative;
  align-self: flex-start;
}

  .TextArea__Wrapper_1fkNu label {
    position: absolute;
    top: 13px;
    display: block;
    max-width: calc(100% - var(--padding) * 2);
    background-color: transparent;
    color: rgba(0, 0, 0, 0.5);
    color: rgba(var(--rgb--black), 0.5);
    font-size: 16px;
    line-height: 22px;
    pointer-events: none;
    transform: translate(var(--padding), 0);
    transform-origin: left;
    transition: background-color var(--time), transform var(--time), padding var(--time),
      color var(--time);
  }

  .TextArea__Wrapper_1fkNu textarea {
    width: 100%;
    min-height: 94px;
    padding: 14px var(--padding) 16px;
    border: 1px solid rgba(18, 82, 245, 0.2);
    border: 1px solid rgba(var(--rgb--second-blue), 0.2);
    border-radius: 2px;
    outline: none;
    resize: vertical;
    transition: border-color var(--time), color var(--time);
  }

  .TextArea__Wrapper_1fkNu textarea.TextArea___active_1OWkT,
    .TextArea__Wrapper_1fkNu textarea:focus {
      border-color: rgb(18, 82, 245);
      border-color: var(--color--second-blue);
    }

  .TextArea__Wrapper_1fkNu textarea.TextArea___active_1OWkT ~ label, .TextArea__Wrapper_1fkNu textarea:focus ~ label {
        overflow: hidden;
        max-width: calc((100% - var(--padding) * 2) / 0.72);
        padding: 0 6px;
        background-color: rgb(255, 255, 255);
        background-color: var(--color--white);
        color: rgb(0, 0, 0);
        color: var(--color--black);
        text-overflow: ellipsis;
        transform: var(--transform--label);
        white-space: nowrap;
      }

  .TextArea__Wrapper_1fkNu textarea.TextArea___error_3nimX {
      border-color: rgb(249, 57, 57);
      border-color: var(--color--red);
      color: rgba(249, 57, 57, 0.3);
      color: rgba(var(--rgb--red), 0.3);
    }

  .TextArea__Wrapper_1fkNu textarea.TextArea___error_3nimX ~ label {
        color: rgb(249, 57, 57);
        color: var(--color--red);
      }

  .TextArea__Wrapper_1fkNu textarea:-webkit-autofill {
      box-shadow: 0 0 0 1000px hsl(47, 100%, 100%) inset;
      -webkit-text-fill-color: rgb(0, 0, 0);
      -webkit-text-fill-color: var(--color--black);
    }

  .TextArea__Wrapper_1fkNu textarea:-webkit-autofill ~ label {
        overflow: hidden;
        max-width: calc((100% - var(--padding) * 2) / 0.72);
        padding: 0 6px;
        background-color: rgb(255, 255, 255);
        background-color: var(--color--white);
        color: rgb(0, 0, 0);
        color: var(--color--black);
        text-overflow: ellipsis;
        transform: var(--transform--label);
        white-space: nowrap;
      }
