.Bottom__Wrapper_2ZHAp {
  padding-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  line-height: 22px;
}

  @media screen and (max-width: 767px) {.Bottom__Wrapper_2ZHAp {
    padding-bottom: 72px
}
  }

  @media (--screen--xs-max) {.Bottom__Wrapper_2ZHAp {
    padding-bottom: 72px
}
  }

  @media screen and (min-width: 768px) {.Bottom__Wrapper_2ZHAp {
    padding-bottom: 40px;
    margin-top: auto
}
  }

  @media (--screen--sm-min) {.Bottom__Wrapper_2ZHAp {
    padding-bottom: 40px;
    margin-top: auto
}
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {.Bottom__Wrapper_2ZHAp {
    --noOfColumns: 2;

    grid-row-gap: 17px
}
  }

  @media (--screen--sm-only) {.Bottom__Wrapper_2ZHAp {
    --noOfColumns: 2;

    grid-row-gap: 17px
}
  }

  @media screen and (min-width: 1024px) {.Bottom__Wrapper_2ZHAp {
    --noOfColumns: 4
}
  }

  @media (--screen--md-min) {.Bottom__Wrapper_2ZHAp {
    --noOfColumns: 4
}
  }

@media screen and (max-width: 767px) {

.Bottom__Block_24PgP:first-child {
      margin-bottom: 40px
  }
    }

@media (--screen--xs-max) {

.Bottom__Block_24PgP:first-child {
      margin-bottom: 40px
  }
    }

@media screen and (max-width: 767px) {

.Bottom__Block_24PgP:nth-child(3) {
      margin: 56px 0
  }
    }

@media (--screen--xs-max) {

.Bottom__Block_24PgP:nth-child(3) {
      margin: 56px 0
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Bottom__Block_24PgP {
    display: flex;
    flex-direction: column
}
  }

@media (--screen--sm-only) {

.Bottom__Block_24PgP {
    display: flex;
    flex-direction: column
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Bottom__Social_CTaAV,
.Bottom__Links_26YyD {
    margin-top: auto
}
  }

@media (--screen--sm-only) {

.Bottom__Social_CTaAV,
.Bottom__Links_26YyD {
    margin-top: auto
}
  }

@media screen and (max-width: 767px) {

.Bottom__Link_2hvMX:not(:last-child) {
      margin-bottom: 24px
  }
    }

@media (--screen--xs-max) {

.Bottom__Link_2hvMX:not(:last-child) {
      margin-bottom: 24px
  }
    }

@media screen and (min-width: 768px) {

.Bottom__Link_2hvMX:not(:last-child) {
      margin-bottom: 16px
  }
    }

@media (--screen--sm-min) {

.Bottom__Link_2hvMX:not(:last-child) {
      margin-bottom: 16px
  }
    }

@media screen and (min-width: 768px) {

.Bottom__Link_2hvMX {
    font-size: 12px;
    line-height: 16px
}
  }

@media (--screen--sm-min) {

.Bottom__Link_2hvMX {
    font-size: 12px;
    line-height: 16px
}
  }

.Bottom__Title_MYPuh {
  font-weight: 500;
}

@media screen and (max-width: 767px) {

.Bottom__Title_MYPuh {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--xs-max) {

.Bottom__Title_MYPuh {
    font-size: 26px;
    line-height: 32px
}
  }

@media screen and (max-width: 1023px) {

.Bottom__Title_MYPuh {
    margin-bottom: 16px
}
  }

@media (--screen--sm-max) {

.Bottom__Title_MYPuh {
    margin-bottom: 16px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Bottom__Title_MYPuh {
    margin-bottom: 24px
}
  }

@media (--screen--md-only) {

.Bottom__Title_MYPuh {
    margin-bottom: 24px
}
  }

@media screen and (min-width: 1366px) {

.Bottom__Title_MYPuh {
    margin-bottom: 16px
}
  }

@media (--screen--lg-min) {

.Bottom__Title_MYPuh {
    margin-bottom: 16px
}
  }

.Bottom__TitleEmpty_2GYwC {
}
