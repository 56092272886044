.Transitions__FadeEnter_3tnss {
  opacity: 0;
}

.Transitions__FadeEnterActive_2NBFY {
  opacity: 1;
  transition: opacity 0.3s;
}

.Transitions__FadeExitActive_31Kdi {
  opacity: 0;
  transition: opacity 0.3s;
}
