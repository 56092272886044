:root {
  --rgb--black: 0, 0, 0;
  --rgb--white: 255, 255, 255;
  --rgb--main-blue: 0, 18, 117;
  --rgb--second-blue: 18, 82, 245;
  --rgb--accent-light-blue: 0, 196, 255;
  --rgb--bg-light: 240, 248, 255;
  --rgb--red: 249, 57, 57;
  --rgb--green: 0, 198, 91;
  --rgb--search-highlight: 201, 230, 255;
  --color--black: rgb(var(--rgb--black));
  --color--white: rgb(var(--rgb--white));
  --color--main-blue: rgb(var(--rgb--main-blue));
  --color--second-blue: rgb(var(--rgb--second-blue));
  --color--green: rgb(var(--rgb--green));
  --color--accent-light-blue: rgb(var(--rgb--accent-light-blue));
  --color--bg-light: rgb(var(--rgb--bg-light));
  --color--red: rgb(var(--rgb--red));
  --color--search-highlight: rgb(var(--rgb--search-highlight));
  --padding--xs: 4px;
  --padding--sm: 8px;
  --padding--md: 12px;
  --padding--lg: 16px;
  --padding--xl: 24px;
  --padding--2xl: 32px;
  --padding--3xl: 40px;
}

  @media screen and (max-width: 767px) {:root {
    --padding--5xl: 64px
}
  }

  @media (--screen--xs-max) {:root {
    --padding--5xl: 64px
}
  }

  @media screen and (min-width: 768px) and (max-width: 1365px) {:root {
    --padding--5xl: 72px
}
  }

  @media (--screen--sm-min) and (--screen--md-max) {:root {
    --padding--5xl: 72px
}
  }

  @media screen and (max-width: 1365px) {:root {
    --padding--4xl: 48px
}
  }

  @media (--screen--md-max) {:root {
    --padding--4xl: 48px
}
  }

  @media screen and (min-width: 1366px) {:root {
    --padding--4xl: 64px;
    --padding--5xl: 88px
}
  }

  @media (--screen--lg-min) {:root {
    --padding--4xl: 64px;
    --padding--5xl: 88px
}
  }

@custom-media --screen--xs-min screen and (min-width: 1px);

@custom-media --screen--xs-max screen and (max-width: 767px);

@custom-media --screen--sm-min screen and (min-width: 768px);

@custom-media --screen--sm-only screen and (min-width: 768px) and (max-width: 1023px);

@custom-media --screen--sm-max screen and (max-width: 1023px);

@custom-media --screen--md-min screen and (min-width: 1024px);

@custom-media --screen--md-only screen and (min-width: 1024px) and (max-width: 1365px);

@custom-media --screen--md-max screen and (max-width: 1365px);

@custom-media --screen--lg-min screen and (min-width: 1366px);

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

hr {
  overflow: visible;
  height: 0;
  box-sizing: content-box;
}

pre {
  /* stylelint-disable-next-line font-family-no-duplicate-names */
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  /* stylelint-disable-next-line font-family-no-duplicate-names */
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  display: table;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

@font-face {
  font-display: swap;
  font-family: "TT Norms";
  font-style: normal;
  font-weight: 300;
  src: url(/_next/static/chunks/fonts/TTNorms-Light-7b02e6ad8c16925157d246efd486b92b.woff) format("woff");
}

@font-face {
  font-display: swap;
  font-family: "TT Norms";
  font-style: italic;
  font-weight: 300;
  src: url(/_next/static/chunks/fonts/TTNorms-LightItalic-42fb5849b34ac3a906fc0783d6271079.woff) format("woff");
}

@font-face {
  font-display: swap;
  font-family: "TT Norms";
  font-style: normal;
  font-weight: 400;
  src: url(/_next/static/chunks/fonts/TTNorms-Regular-d918d51ed4c69bc6b4a019c8fa52824e.woff) format("woff");
}

@font-face {
  font-display: swap;
  font-family: "TT Norms";
  font-style: normal;
  font-weight: 500;
  src: url(/_next/static/chunks/fonts/TTNorms-Medium-a2d5538f829e934b8af40c17ce5fa947.woff) format("woff");
}

@font-face {
  font-display: swap;
  font-family: "TT Norms";
  font-style: normal;
  font-weight: 600;
  src: url(/_next/static/chunks/fonts/TTNorms-Bold-914426f99d106a91ba3c61a2c7f723e5.woff) format("woff");
}

@media screen and (min-width: 1px) {
    .d-none {
      display: none !important;
    }

    .d-inline-block {
      display: inline-block !important;
    }

    .d-block {
      display: block !important;
    }

    .d-flex {
      display: flex !important;
    }

    .d-grid {
      display: grid !important;
    }
  }

@media (--screen--xs-min) {
    .d-none {
      display: none !important;
    }

    .d-inline-block {
      display: inline-block !important;
    }

    .d-block {
      display: block !important;
    }

    .d-flex {
      display: flex !important;
    }

    .d-grid {
      display: grid !important;
    }
  }

@media screen and (min-width: 768px) {
    .d-sm-none {
      display: none !important;
    }

    .d-sm-inline-block {
      display: inline-block !important;
    }

    .d-sm-block {
      display: block !important;
    }

    .d-sm-flex {
      display: flex !important;
    }

    .d-sm-grid {
      display: grid !important;
    }
  }

@media (--screen--sm-min) {
    .d-sm-none {
      display: none !important;
    }

    .d-sm-inline-block {
      display: inline-block !important;
    }

    .d-sm-block {
      display: block !important;
    }

    .d-sm-flex {
      display: flex !important;
    }

    .d-sm-grid {
      display: grid !important;
    }
  }

@media screen and (min-width: 1024px) {
    .d-md-none {
      display: none !important;
    }

    .d-md-inline-block {
      display: inline-block !important;
    }

    .d-md-block {
      display: block !important;
    }

    .d-md-flex {
      display: flex !important;
    }

    .d-md-grid {
      display: grid !important;
    }
  }

@media (--screen--md-min) {
    .d-md-none {
      display: none !important;
    }

    .d-md-inline-block {
      display: inline-block !important;
    }

    .d-md-block {
      display: block !important;
    }

    .d-md-flex {
      display: flex !important;
    }

    .d-md-grid {
      display: grid !important;
    }
  }

@media screen and (min-width: 1366px) {
    .d-lg-none {
      display: none !important;
    }

    .d-lg-inline-block {
      display: inline-block !important;
    }

    .d-lg-block {
      display: block !important;
    }

    .d-lg-flex {
      display: flex !important;
    }

    .d-lg-grid {
      display: grid !important;
    }
  }

@media (--screen--lg-min) {
    .d-lg-none {
      display: none !important;
    }

    .d-lg-inline-block {
      display: inline-block !important;
    }

    .d-lg-block {
      display: block !important;
    }

    .d-lg-flex {
      display: flex !important;
    }

    .d-lg-grid {
      display: grid !important;
    }
  }

:root {
    --gap: 32px;
  }

.container {
    position: relative;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

@media screen and (max-width: 767px) {

.container {
      max-width: 480px;
      padding-right: 24px;
      padding-left: 24px
  }
    }

@media (--screen--xs-max) {

.container {
      max-width: 480px;
      padding-right: 24px;
      padding-left: 24px
  }
    }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.container {
      max-width: 720px
  }
    }

@media (--screen--sm-only) {

.container {
      max-width: 720px
  }
    }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.container {
      max-width: 896px
  }
    }

@media (--screen--md-only) {

.container {
      max-width: 896px
  }
    }

@media screen and (min-width: 1366px) {

.container {
      max-width: 1174px
  }
    }

@media (--screen--lg-min) {

.container {
      max-width: 1174px
  }
    }

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(var(--gap) / 2 * -1);
    margin-left: calc(var(--gap) / 2 * -1);
  }

.col {
    flex: 0 0 50%;
    padding: 0 calc(var(--gap) / 2);
  }

.grid {
    --noOfColumns: 1;
    --column-gap: var(--gap);

    display: grid;
    grid-column-gap: var(--column-gap);
    grid-template-columns: repeat(var(--noOfColumns), minmax(0, 1fr));
  }

@media screen and (min-width: 768px) {

.grid--col-12 {
        grid-template-columns: repeat(12, 1fr)
    }
      }

@media (--screen--sm-min) {

.grid--col-12 {
        grid-template-columns: repeat(12, 1fr)
    }
      }

@media screen and (min-width: 768px) {
    .wysiwyg-col-right {
      grid-column: 9 / span 4;
    }
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .wysiwyg-col-left {
      grid-column: span 8;
    }
  }

@media screen and (min-width: 1024px) {
    .wysiwyg-col-left {
      grid-column: span 7;
    }
  }

@media screen and (max-width: 1365px) {
    .wysiwyg-block + .wysiwyg-block,
    .wysiwyg-block + .wysiwyg-block-title-logo {
      margin-top: 48px;
    }
  }

@media screen and (min-width: 1365px) {
    .wysiwyg-block + .wysiwyg-block,
    .wysiwyg-block + .wysiwyg-block-title-logo {
      margin-top: 64px;
    }
  }

.wysiwyg-block a:not([class]) {
    color: rgb(18, 82, 245);
    font-weight: 500;
    word-break: break-all;
  }

.wysiwyg-block a:not([class])::after {
    content: none;
  }

.wysiwyg-block-title-logo + .wysiwyg-block {
    margin-top: 24px;
  }

.wysiwyg-col-left br {
    display: none;
  }

.wysiwyg-col-left * + h1,
  .wysiwyg-col-left * + h2,
  .wysiwyg-col-left * + h4,
  .wysiwyg-col-left * + p {
    margin-top: 24px;
  }

.wysiwyg-col-left h3 + *,
  .wysiwyg-col-left * + h3 {
    margin-top: 32px;
  }

.wysiwyg-col-left p > b {
    font-weight: 500;
  }

.wysiwyg-col-left ul,
  .wysiwyg-col-left ol {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
  }

.wysiwyg-col-left ul > li,
  .wysiwyg-col-left ol > li {
    position: relative;
    padding-left: 20px;
  }

.wysiwyg-col-left ul > li::before,
  .wysiwyg-col-left ol > li::before {
    position: absolute;
  }

.wysiwyg-col-left ul > li:not(:last-child),
  .wysiwyg-col-left ol > li:not(:last-child) {
    margin-bottom: 16px;
  }

.wysiwyg-col-left ol {
    counter-reset: counter;
  }

.wysiwyg-col-left ol > li::before {
    top: 2px;
    left: 2px;
    color: rgb(18, 82, 245);
    content: counter(counter);
    counter-increment: counter;
    font-size: 12px;
    line-height: 16px;
  }

.wysiwyg-col-left ul > li::before {
    top: 9px;
    left: 0;
    width: 8px;
    height: 1px;
    background: rgb(18, 82, 245);
    content: "";
  }

.wysiwyg-col-left * + ul,
  .wysiwyg-col-left * + ol {
    margin-top: 24px;
  }

.wysiwyg-col-left td,
  .wysiwyg-col-left th {
    padding-top: 0;
    padding-right: 55px;
    padding-bottom: 15px;
    padding-left: 0;
  }

@media screen and (max-width: 1365px) {
    .wysiwyg-block h2,
    .wysiwyg-block h3 {
      font-size: 26px;
      line-height: 32px;
    }
  }

@media screen and (min-width: 1366px) {
    .wysiwyg-block h2,
    .wysiwyg-block h3 {
      font-size: 32px;
      line-height: 38px;
    }
  }

@media screen and (max-width: 767px) {
    .wysiwyg-block h1 {
      margin-left: -2px;
      font-size: 40px;
      line-height: 48px;
    }
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {
    .wysiwyg-block h1 {
      margin-left: -3px;
      font-size: 48px;
      line-height: 56px;
    }
  }

@media screen and (min-width: 1366px) {
    .wysiwyg-block h1 {
      margin-left: -4px;
      font-size: 56px;
      line-height: 64px;
    }
  }

.wysiwyg-block h1,
  .wysiwyg-block h3 {
    font-weight: 300;
  }

.wysiwyg-block h2,
  .wysiwyg-block h4,
  .wysiwyg-block h5 {
    font-weight: 500;
  }

.wysiwyg-block h4 {
    font-size: 16px;
    line-height: 22px;
  }

.wysiwyg-block h5 {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-transform: uppercase;
  }

@media screen and (max-width: 767px) {
    .expand-link__wrapper {
      margin-top: 32px;
    }
  }

.expand-link__description {
    margin-top: 12px;
    font-size: 12px;
    line-height: 16px;
  }

.expand-link__link {
    margin-top: 16px;
  }

@media screen and (max-width: 767px) {
    .foot-note__wrapper {
      margin-top: 32px;
    }
  }

.foot-note__inner {
    position: relative;
    padding-left: 19px;
  }

.foot-note__inner::before {
    position: absolute;
    top: 4px;
    bottom: 3px;
    left: 0;
    width: 3px;
    background-color: rgb(18, 82, 245);
    content: "";
  }

.foot-note__digit {
    margin-bottom: 11px;
    color: rgb(18, 82, 245);
    font-size: 32px;
    font-weight: 300;
    line-height: 33px;
  }

@media screen and (max-width: 767px) {
    .logo__wrapper {
      height: 40px;
      margin-top: 32px;
      margin-bottom: 8px;
    }
  }

@media screen and (min-width: 768px) {
    .logo__wrapper {
      margin-left: auto;
    }
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {
    .logo__wrapper {
      margin-top: 10px;
    }
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .logo__wrapper {
      height: 30px;
    }
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .logo__wrapper {
      height: 40px;
    }
  }

@media screen and (min-width: 1366px) {
    .logo__wrapper {
      height: 50px;
      margin-top: 8px;
    }
  }

@media screen and (max-width: 767px) {
    .logo__wrapper > img {
      margin-right: auto;
    }
  }

@media screen and (min-width: 768px) {
    .logo__wrapper > img {
      margin-left: auto;
    }
  }

.logo__wrapper > img {
    max-height: 100%;
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .picture__wrapper {
      margin-right: -79px;
    }
  }

@media screen and (min-width: 1366px) {
    .picture__wrapper {
      margin-right: -101px;
    }
  }

@media screen and (max-width: 767px) {
    .picture__image {
      margin-right: -24px;
      margin-left: -24px;
    }
  }

* + .picture__wrapper,
  div.picture__wrapper + * {
    margin-top: 40px;
  }

.picture__image {
    overflow: hidden;
    border-radius: 2px;
  }

.picture__caption {
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
  }

@media screen and (max-width: 767px) {
    .quote-author__job {
      font-size: 12px;
      line-height: 16px;
    }
  }

* + .quote-container,
  div.quote-container + * {
    margin-top: 40px;
  }

.quote-wrapper {
    position: relative;
    padding-left: 24px;
    font-style: italic;
    font-weight: 300;
  }

.quote-wrapper::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 19px;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguMzcyIDNMMy44MTQgOS40OSA4LjM3MiAxNkg0LjU3N0wwIDkuNDkgNC41NzcgM2gzLjc5NXpNMTYgM2wtNC41NzcgNi40OUwxNiAxNmgtMy44MTRMNy42MjggOS40OSAxMi4xODYgM0gxNnoiIGZpbGw9IiMxMjUyRjUiLz48L3N2Zz4K");
    content: "";
  }

.quote-author {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-left: 24px;
  }

.quote-author__photo {
    overflow: hidden;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 16px;
    border-radius: 2px;
  }

.quote-author__name {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

.quote-author__job {
    margin-top: 8px;
  }

@media screen and (max-width: 767px) {
    .similar-link__wrapper {
      margin-top: 40px;
    }
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .similar-link__picture {
      max-width: 220px;
      max-height: 135px;
    }
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .similar-link__picture {
      max-width: 280px;
      max-height: 170px;
    }
  }

@media screen and (min-width: 1366px) {
    .similar-link__picture {
      max-width: 370px;
      max-height: 170px;
    }
  }

.similar-link__card {
    position: relative;
    margin-top: 16px;
  }

.similar-link__anchor {
    position: absolute;
    width: 100%;
    height: 100%;
  }

.similar-link__picture {
    overflow: hidden;
    border-radius: 2px;
  }

.similar-link__title {
    margin-top: 16px;
  }

.similar-link__description {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
  }

@media screen and (max-width: 767px) {
    .wysiwyg-table__wrapper {
      position: relative;
      left: 50%;
      width: 100vw;
      padding-left: 24px;
      margin-left: -50vw;
      overflow-x: auto;
    }

    .wysiwyg-table__wrapper table {
      margin-right: auto;
      margin-left: auto;
    }

    .wysiwyg-table__inner {
      min-width: 524px;
      padding-right: 24px;
    }
  }

@media screen and (min-width: 768px) {
    .wysiwyg-table__wrapper {
      grid-column: span 12;
    }
  }

* + .wysiwyg-table__wrapper,
  div.wysiwyg-table__wrapper + * {
    margin-top: 40px;
  }

.wysiwyg-table__wrapper table {
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
  }

.wysiwyg-table__wrapper table thead th {
    border-bottom: 1px solid #1252f5;
    text-align: left;
  }

.wysiwyg-table__wrapper table b {
    font-weight: 500;
  }

.wysiwyg-table__wrapper table td,
  .wysiwyg-table__wrapper table th {
    padding-top: 0;
    padding-right: 55px;
    padding-bottom: 15px;
    padding-left: 0;
    border: none;
  }

.wysiwyg-table__wrapper table th {
    font-weight: 500;
  }

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-width: 320px;
  min-height: 100%;
  background: rgb(255, 255, 255);
  background: var(--color--white);
  color: rgb(0, 0, 0);
  color: var(--color--black);
  font-family: "TT Norms", sans-serif;
  font-feature-settings: "liga" off;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 22px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: currentColor;
  outline: none;
  text-decoration: none;
}

a:not([class]) {
  position: relative;
}

/* TODO: не работает для многострочных элементов. псевдоэлемнт сделан для opacity */

a:not([class])::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentColor;
    content: "";
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
    will-change: opacity;
  }

a:not([class]):hover::after {
      opacity: 0.5;
    }

button {
  border: 0;
  outline: none;
}

button:hover {
    cursor: pointer;
  }

img:not([class]),
svg:not([class]) {
  display: block;
  max-width: 100%;
}

.undefined {
    background: red !important;
    color: red !important;
  }

/* Отмена скрытия контента для печати */

@media print {
  html body * {
    visibility: visible !important;
  }
}
