.Select__Wrapper_1KhTL {
  position: relative;
}

.Select__Label_3iaaP {
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.3);
  color: rgba(var(--rgb--black), 0.3);
  font-weight: 500;
}

.Select__Item_2njdS {
  color: rgb(0, 0, 0);
  color: var(--color--black);
  font-weight: 500;
  transition: color ease-in-out 0.3s;
}

.Select__Item_2njdS:hover {
    color: rgb(18, 82, 245);
    color: var(--color--second-blue);
    cursor: pointer;
  }

.Select__Item_2njdS:not(:last-child) {
    margin-bottom: 24px;
  }

.Select__Field_3-Hzm {
  transition: color 0.3s, border-color 0.3s;
}

.Select__FieldError_1CRaW {
  border-color: rgb(249, 57, 57);
  border-color: var(--color--red);
  color: rgb(249, 57, 57);
  color: var(--color--red);
}
