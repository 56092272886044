.Popup__Wrapper_weh5t {
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 18, 117, 0.9);
  background: rgba(var(--rgb--main-blue), 0.9);
}

.Popup__Inner_2vla1 {
  width: 100%;
  overflow-y: auto;
}

@media screen and (max-width: 767px) {

.Popup__Inner_2vla1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100%
}
  }

@media (--screen--xs-max) {

.Popup__Inner_2vla1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100%
}
  }

@media screen and (min-width: 768px) {

.Popup__Inner_2vla1 {
    display: flex;
    height: 100%;
    flex-direction: column
}
  }

@media (--screen--sm-min) {

.Popup__Inner_2vla1 {
    display: flex;
    height: 100%;
    flex-direction: column
}
  }

.Popup__ScrollableArea_1Yfzl {
  position: relative;
  min-height: 40px;
  border-radius: 2px;
}

@media screen and (max-width: 767px) {

.Popup__ScrollableArea_1Yfzl {
    width: 100%
}
  }

@media (--screen--xs-max) {

.Popup__ScrollableArea_1Yfzl {
    width: 100%
}
  }

@media screen and (min-width: 768px) {

.Popup__ScrollableArea_1Yfzl {
    top: 10%;
    margin: 0 auto
}
  }

@media (--screen--sm-min) {

.Popup__ScrollableArea_1Yfzl {
    top: 10%;
    margin: 0 auto
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Popup__ScrollableAreaDefault_kwpi9 {
    max-width: 594px
}
  }

@media (--screen--sm-only) {

.Popup__ScrollableAreaDefault_kwpi9 {
    max-width: 594px
}
  }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.Popup__ScrollableAreaDefault_kwpi9 {
    max-width: 586px
}
  }

@media (--screen--md-only) {

.Popup__ScrollableAreaDefault_kwpi9 {
    max-width: 586px
}
  }

@media screen and (min-width: 1366px) {

.Popup__ScrollableAreaDefault_kwpi9 {
    max-width: 772px
}
  }

@media (--screen--lg-min) {

.Popup__ScrollableAreaDefault_kwpi9 {
    max-width: 772px
}
  }

.Popup__ContentWrapper_SMv5i {
  position: relative;
}

.Popup__Content_1CxJH {
  overflow: hidden;
  margin-top: -40px;
  background: rgb(255, 255, 255);
  background: var(--color--white);
}

@media screen and (max-width: 767px) {

.Popup__Content_1CxJH {
    padding: 0 24px;
    padding-top: 16px
}
  }

@media (--screen--xs-max) {

.Popup__Content_1CxJH {
    padding: 0 24px;
    padding-top: 16px
}
  }

@media screen and (min-width: 768px) {

.Popup__Content_1CxJH {
    margin-bottom: 60px
}
  }

@media (--screen--sm-min) {

.Popup__Content_1CxJH {
    margin-bottom: 60px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Popup__Content_1CxJH {
    padding: 0 40px;
    padding-top: 24px
}
  }

@media (--screen--sm-only) {

.Popup__Content_1CxJH {
    padding: 0 40px;
    padding-top: 24px
}
  }

@media screen and (min-width: 1024px) {

.Popup__Content_1CxJH {
    padding: 0 48px;
    padding-top: 32px
}
  }

@media (--screen--md-min) {

.Popup__Content_1CxJH {
    padding: 0 48px;
    padding-top: 32px
}
  }

.Popup__Close_3VrfK {
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  top: 0;
  width: 40px;
  height: 40px;
  margin-left: auto;
  background: rgb(18, 82, 245);
  background: var(--color--second-blue);
}

.Popup__Close_3VrfK::before,
  .Popup__Close_3VrfK::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 15px;
    background: rgb(255, 255, 255);
    background: var(--color--white);
    content: "";
    transform: translate(-50%, -50%) rotate(var(--rotate));
  }

.Popup__Close_3VrfK::before {
    --rotate: 45deg;
  }

.Popup__Close_3VrfK::after {
    --rotate: -45deg;
  }

.Popup__Close_3VrfK:hover {
    cursor: pointer;
  }
