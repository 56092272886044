.ShadowField__Field_1-ck7 {
  position: relative;
  z-index: 1;
  padding: 12px 64px 14px 24px;
  border: 1px solid rgba(18, 82, 245, 0.1);
  border: 1px solid rgba(var(--rgb--second-blue), 0.1);
  background: rgb(255, 255, 255);
  background: var(--color--white);
  border-radius: 2px;
  box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
  color: rgb(18, 82, 245);
  color: var(--color--second-blue);
  font-weight: 500;
}

  .ShadowField__Field_1-ck7:hover {
    cursor: pointer;
  }

.ShadowField__Arrow_1rnv8 {
  --rotate: 45deg;

  opacity: 0.3;
}

.ShadowField__Arrow_1rnv8::before,
  .ShadowField__Arrow_1rnv8::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 1px;
    background: rgb(18, 82, 245);
    background: var(--color--second-blue);
    content: "";
    transition: transform ease-in-out 0.3s;
    will-change: transform;
  }

.ShadowField__Arrow_1rnv8::after {
    transform: translate(-50%, -50%) translateX(2px) rotate(calc(var(--rotate) * -1));
  }

.ShadowField__Arrow_1rnv8::before {
    transform: translate(-50%, -50%) translateX(-3px) rotate(var(--rotate));
  }

[data-state="open"] .ShadowField__Arrow_1rnv8 {
    --rotate: -45deg;
  }

.ShadowField__Icon_3E357 {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 22px;
  height: 22px;
  transform: translateY(-50%);
}

.ShadowField__Plus_1b4ER {
  opacity: 0.3;
}

.ShadowField__Plus_1b4ER::before,
  .ShadowField__Plus_1b4ER::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 1px;
    background: rgb(18, 82, 245);
    background: var(--color--second-blue);
    content: "";
  }

.ShadowField__Plus_1b4ER::after {
    transform: translate(-50%, -50%);
  }

.ShadowField__Plus_1b4ER::before {
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }

.ShadowField__Calendar_1AP2m {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS41IDEuNWgxM1YxMWExLjUgMS41IDAgMDEtMS41IDEuNUgyQTEuNSAxLjUgMCAwMS41IDExVjEuNXpNNCAwdjMuNU0xMyA1LjVIMU0xMCAwdjMuNSIgc3Ryb2tlPSIjMTI1MkY1Ii8+PC9zdmc+);
  background-position: 50%;
  background-repeat: no-repeat;
  opacity: 0.3;
}
