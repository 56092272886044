.Heading__H1_36DHE,
.Heading__H3_Ft54O {
  font-weight: 300;
}

@media screen and (max-width: 1365px) {

.Heading__H2_19sH3,
.Heading__H3_Ft54O {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--md-max) {

.Heading__H2_19sH3,
.Heading__H3_Ft54O {
    font-size: 26px;
    line-height: 32px
}
  }

@media screen and (min-width: 1366px) {

.Heading__H2_19sH3,
.Heading__H3_Ft54O {
    font-size: 32px;
    line-height: 38px
}
  }

@media (--screen--lg-min) {

.Heading__H2_19sH3,
.Heading__H3_Ft54O {
    font-size: 32px;
    line-height: 38px
}
  }

.Heading__H2_19sH3,
.Heading__H4_2JUAM,
.Heading__H5_3m4Tb {
  font-weight: 500;
}

@media screen and (max-width: 767px) {

.Heading__H1_36DHE {
    margin-left: -2px;
    font-size: 40px;
    line-height: 48px
}
  }

@media (--screen--xs-max) {

.Heading__H1_36DHE {
    margin-left: -2px;
    font-size: 40px;
    line-height: 48px
}
  }

@media screen and (min-width: 768px) and (max-width: 1365px) {

.Heading__H1_36DHE {
    margin-left: -3px;
    font-size: 48px;
    line-height: 56px
}
  }

@media (--screen--sm-min) and (--screen--md-max) {

.Heading__H1_36DHE {
    margin-left: -3px;
    font-size: 48px;
    line-height: 56px
}
  }

@media screen and (min-width: 1366px) {

.Heading__H1_36DHE {
    margin-left: -4px;
    font-size: 56px;
    line-height: 64px
}
  }

@media (--screen--lg-min) {

.Heading__H1_36DHE {
    margin-left: -4px;
    font-size: 56px;
    line-height: 64px
}
  }

@media print {

.Heading__H1_36DHE {
    margin: 20px 0 40px;
    font-weight: 700;
    line-height: 1.2
}
  }

.Heading__H4_2JUAM {
  font-size: 16px;
  line-height: 22px;
}

.Heading__H5_3m4Tb {
  color: rgba(0, 0, 0, 0.3);
  color: rgba(var(--rgb--black), 0.3);
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-transform: uppercase;
}

.Heading__H5_3m4Tb.Heading__OnDark_2tKug {
    color: rgba(255, 255, 255, 0.5);
    color: rgba(var(--rgb--white), 0.5);
  }

.Heading__OnDark_2tKug {
  /* TODO: */
}
