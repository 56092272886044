.Theme__Light_2_koa {
  background: rgb(255, 255, 255);
  background: var(--color--white);
  color: rgb(0, 0, 0);
  color: var(--color--black);
}

.Theme__Dark_2l824 {
  background: rgb(0, 18, 117);
  background: var(--color--main-blue);
  color: rgb(255, 255, 255);
  color: var(--color--white);
}
