.Popover__Wrapper_1i4a2 {
  position: absolute;
  z-index: 2;
  width: 100%;
  max-height: 320px;
  padding: 16px 24px 24px;
  border: 1px solid rgba(18, 82, 245, 0.1);
  border: 1px solid rgba(var(--rgb--second-blue), 0.1);
  margin-top: 16px;
  background: rgb(255, 255, 255);
  background: var(--color--white);
  border-radius: 2px;
  box-shadow: 0 16px 24px rgba(18, 82, 245, 0.1);
  overflow-y: auto;
}
