/* eslint-disable */

.Bottom__Bottom_3IP-1 {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

@media screen and (min-width: 768px) {

.Bottom__Bottom_3IP-1 {
    padding-top: 32px;
    border-top: 1px solid rgba(255, 255, 255, 0.2)
}
  }

@media (--screen--sm-min) {

.Bottom__Bottom_3IP-1 {
    padding-top: 32px;
    border-top: 1px solid rgba(255, 255, 255, 0.2)
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Bottom__Bottom_3IP-1 {
    --noOfColumns: 2
}
  }

@media (--screen--sm-only) {

.Bottom__Bottom_3IP-1 {
    --noOfColumns: 2
}
  }

@media screen and (min-width: 1024px) {

.Bottom__Bottom_3IP-1 {
    --noOfColumns: 4 !important
}
  }

@media (--screen--md-min) {

.Bottom__Bottom_3IP-1 {
    --noOfColumns: 4 !important
}
  }

@media print {

.Bottom__Bottom_3IP-1 {
    color: #000
}
  }

@media screen and (max-width: 767px) {

.Bottom__Block_2ZQ_n {
    margin-bottom: 48px
}
  }

@media (--screen--xs-max) {

.Bottom__Block_2ZQ_n {
    margin-bottom: 48px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Bottom__Block_2ZQ_n {
    margin-bottom: 40px
}

    .Bottom__Block_2ZQ_n:last-child {
      /* grid-column: 2; */
    }
  }

@media (--screen--sm-only) {

.Bottom__Block_2ZQ_n {
    margin-bottom: 40px
}

    .Bottom__Block_2ZQ_n:last-child {
      /* grid-column: 2; */
    }
  }

@media screen and (min-width: 1024px) {

.Bottom__Block_2ZQ_n {
    display: flex;
    flex-direction: column
}
  }

@media (--screen--md-min) {

.Bottom__Block_2ZQ_n {
    display: flex;
    flex-direction: column
}
  }

@media print {

.Bottom__Block_2ZQ_n {
    margin-bottom: 20px
}

    .Bottom__Block_2ZQ_n:nth-child(3) {
      display: none;
    }
  }

.Bottom__Title_1nDjZ {
  margin-bottom: 16px;
  font-weight: 500;
}

@media screen and (max-width: 767px) {

.Bottom__Title_1nDjZ {
    font-size: 26px;
    line-height: 32px
}
  }

@media (--screen--xs-max) {

.Bottom__Title_1nDjZ {
    font-size: 26px;
    line-height: 32px
}
  }

.Bottom__Link_3OKr4:not(:last-child) {
    margin-bottom: 16px;
  }

@media screen and (min-width: 768px) {

.Bottom__Link_3OKr4 {
    font-size: 12px;
    line-height: 16px
}
  }

@media (--screen--sm-min) {

.Bottom__Link_3OKr4 {
    font-size: 12px;
    line-height: 16px
}
  }

.Bottom__Copyright_1x3p5 {
  color: #fff;
  opacity: 0.3;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Bottom__Copyright_1x3p5 {
    margin-top: 40px;
    margin-top: var(--padding--3xl)
}
  }

@media (--screen--sm-only) {

.Bottom__Copyright_1x3p5 {
    margin-top: 40px;
    margin-top: var(--padding--3xl)
}
  }

@media screen and (min-width: 1024px) {

.Bottom__Copyright_1x3p5 {
    margin-top: 48px
}
  }

@media (--screen--md-min) {

.Bottom__Copyright_1x3p5 {
    margin-top: 48px
}
  }

@media print {

.Bottom__Copyright_1x3p5 {
    display: none
}
  }

@media screen and (max-width: 1023px) {

.Bottom__Social_1ATup {
    margin-top: 48px
}
  }

@media (--screen--sm-max) {

.Bottom__Social_1ATup {
    margin-top: 48px
}
  }

@media screen and (min-width: 1024px) {

.Bottom__Social_1ATup {
    margin-top: auto
}
  }

@media (--screen--md-min) {

.Bottom__Social_1ATup {
    margin-top: auto
}
  }

@media print {

.Bottom__Social_1ATup {
    display: none
}
  }

.Bottom__Links_1q0Gt {
  margin-top: auto;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

.Bottom__Subscription_2pbNU {
    width: 220px
}
  }

@media (--screen--sm-only) {

.Bottom__Subscription_2pbNU {
    width: 220px
}
  }

@media screen and (min-width: 1024px) {

.Bottom__Subscription_2pbNU {
    width: 200px
}
  }

@media (--screen--md-min) {

.Bottom__Subscription_2pbNU {
    width: 200px
}
  }

.Bottom__SubscriptionInput_2Sbf2 {
  margin-bottom: 16px;
  margin-bottom: var(--padding--lg);
}

.Bottom__SubscriptionInput_2Sbf2 label {
    background-color: rgb(0, 18, 117);
    background-color: var(--color--main-blue);
    color: rgb(255, 255, 255);
    color: var(--color--white);
    transform: translate(0, -50%);
  }

.Bottom__SubscriptionInput_2Sbf2 input {
    padding: 14px 0 16px;
    border-width: 0 0 1px;
    border-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(var(--rgb--white), 0.3);
    background: transparent;
    border-radius: 0;
    color: rgb(255, 255, 255);
    color: var(--color--white);
  }

.Bottom__SubscriptionInput_2Sbf2 input[class*="_active"],
    .Bottom__SubscriptionInput_2Sbf2 input:focus {
      border-color: rgb(255, 255, 255);
      border-color: var(--color--white);
    }

.Bottom__SubscriptionInput_2Sbf2 input[class*="_active"] ~ label, .Bottom__SubscriptionInput_2Sbf2 input:focus ~ label {
        padding: 0;
        background-color: rgb(0, 18, 117);
        background-color: var(--color--main-blue);
        color: rgb(255, 255, 255);
        color: var(--color--white);
        transform: translate(0, -160%) scale(0.72);
      }

.Bottom__SubscriptionInput_2Sbf2 input[class*="_error"] {
      border-color: rgb(249, 57, 57);
      border-color: var(--color--red);
    }

.Bottom__SubscriptionInput_2Sbf2 input[class*="_error"] ~ label {
        color: rgb(249, 57, 57);
        color: var(--color--red);
      }

.Bottom__SubscriptionInput_2Sbf2 input:-webkit-autofill {
      box-shadow: 0 0 0 1000px rgb(0, 18, 117) inset;
      box-shadow: 0 0 0 1000px var(--color--main-blue) inset;
      -webkit-text-fill-color: rgb(255, 255, 255);
      -webkit-text-fill-color: var(--color--white);
    }

.Bottom__SubscriptionInput_2Sbf2 input:-webkit-autofill ~ label {
        background-color: rgb(0, 18, 117);
        background-color: var(--color--main-blue);
        transform: translate(0, -160%) scale(0.72);
      }

.Bottom__SubscriptionButton_21m8H {
  width: 100%;
}
