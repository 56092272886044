.PopupHotLine__Title_33rHp {
  margin-bottom: 16px;
}

.PopupHotLine__Description_gJGKF {
  margin-bottom: 32px;
}

.PopupHotLine__Description_gJGKF > p + p {
    margin-top: 24px;
  }

.PopupHotLine__Tabs_2BDYd + * {
    margin-top: 16px;
    margin-top: var(--padding--lg);
  }

.PopupHotLine__Footer_26fEr {
  padding: 24px 48px;
  margin-top: 148px;
  margin-bottom: 0;
  background: rgb(240, 248, 255);
  background: var(--color--bg-light);
}

@media screen and (max-width: 767px) {

.PopupHotLine__Footer_26fEr {
    margin-right: -48px;
    margin-left: -48px
}
  }

@media (--screen--xs-max) {

.PopupHotLine__Footer_26fEr {
    margin-right: -48px;
    margin-left: -48px
}
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.PopupHotLine__Footer_26fEr {
    margin-right: -40px;
    margin-left: -40px
}
  }

@media (--screen--sm-only) {

.PopupHotLine__Footer_26fEr {
    margin-right: -40px;
    margin-left: -40px
}
  }

@media screen and (min-width: 1024px) {

.PopupHotLine__Footer_26fEr {
    margin-right: -48px;
    margin-left: -48px
}
  }

@media (--screen--md-min) {

.PopupHotLine__Footer_26fEr {
    margin-right: -48px;
    margin-left: -48px
}
  }

.PopupHotLine__Footer_26fEr > p + p {
    margin-top: 24px;
  }

.PopupHotLine__Footer_26fEr > p {
    font-size: 12px;
    line-height: 16px;
  }

.PopupHotLine__Footer_26fEr > p > a {
      color: rgb(18, 82, 245);
      color: var(--color--second-blue);
    }
