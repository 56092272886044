.SocialLinks__Wrapper_N3vWM .SocialLinks__Item_2igt2 {
    display: inline-block;
  }

    .SocialLinks__Wrapper_N3vWM .SocialLinks__Item_2igt2:hover path {
        fill: rgba(var(--color), 1);
      }

    .SocialLinks__Wrapper_N3vWM .SocialLinks__Item_2igt2 path {
      transition: fill ease-in-out 0.3s;
    }

.SocialLinks__AxisX_1TF7w {
  --color: var(--rgb--white);
  display: flex;
}

@media screen and (max-width: 767px) {

.SocialLinks__AxisX_1TF7w {
    justify-content: center
}
  }

@media (--screen--xs-max) {

.SocialLinks__AxisX_1TF7w {
    justify-content: center
}
  }

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2 {
    flex-shrink: 0;
  }

@media screen and (max-width: 767px) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2:not(:last-child) {
        margin-right: 48px
    }
      }

@media (--screen--xs-max) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2:not(:last-child) {
        margin-right: 48px
    }
      }

@media screen and (min-width: 768px) and (max-width: 1023px) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2:not(:last-child) {
        margin-right: 32px
    }
      }

@media (--screen--sm-only) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2:not(:last-child) {
        margin-right: 32px
    }
      }

@media screen and (min-width: 1024px) and (max-width: 1365px) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2:not(:last-child) {
        margin-right: 16px
    }
      }

@media (--screen--md-only) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2:not(:last-child) {
        margin-right: 16px
    }
      }

@media screen and (min-width: 1366px) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2:not(:last-child) {
        margin-right: 24px
    }
      }

@media (--screen--lg-min) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2:not(:last-child) {
        margin-right: 24px
    }
      }

@media screen and (max-width: 767px) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2 {
      width: 32px
  }
    }

@media (--screen--xs-max) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2 {
      width: 32px
  }
    }

@media screen and (min-width: 768px) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2 {
      width: 25px
  }
    }

@media (--screen--sm-min) {

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2 {
      width: 25px
  }
    }

.SocialLinks__AxisX_1TF7w .SocialLinks__Item_2igt2 path {
      fill: rgba(var(--color), 0.3);
      transition: fill ease-in-out 0.3s;
    }

.SocialLinks__AxisY_1qWwl {
  --color: 143, 174, 255;
  z-index: 5;
  width: 44px;
  padding: 16px 12px;
  background: rgba(var(--color), 0.1);
  border-radius: 2px;
  grid-row-gap: 16px;
}

.SocialLinks__AxisY_1qWwl .SocialLinks__Item_2igt2 {
    width: 20px;
    height: 20px;
  }

.SocialLinks__AxisY_1qWwl .SocialLinks__Item_2igt2 path {
      fill: rgba(var(--color), 0.3);
    }

.SocialLinks__Item_2igt2 {
  /* empty */
}
