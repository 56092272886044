@media screen and (max-width: 1023px) {.Layout__Wrapper__e2Bq > div:first-child {
      padding-top: 103px
  }
    }
    @media (--screen--sm-max) {.Layout__Wrapper__e2Bq > div:first-child {
      padding-top: 103px
  }
    }
    @media screen and (min-width: 1024px) and (max-width: 1365px) {.Layout__Wrapper__e2Bq > div:first-child {
      padding-top: 153px
  }
    }
    @media (--screen--md-only) {.Layout__Wrapper__e2Bq > div:first-child {
      padding-top: 153px
  }
    }
    @media screen and (min-width: 1366px) {.Layout__Wrapper__e2Bq > div:first-child {
      padding-top: 165px
  }
    }
    @media (--screen--lg-min) {.Layout__Wrapper__e2Bq > div:first-child {
      padding-top: 165px
  }
    }

.Layout__Root_1jh3C {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Layout__SocialLinks_2W24R {
  position: fixed;
  top: 50%;
  left: 0;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  transform: translateY(-50%);
}

/* Для печати */

@media print {

.Layout__SocialLinks_2W24R {
    display: none
}
  }
